import React from 'react';

import Greetings from 'widgets/Greetings1C';
import Services from 'widgets/Services1C';
import Configurations from 'widgets/Configurations';
import DevelopmentCycle from 'widgets/DevelopmentCycle';
import Projects from 'widgets/Projects';
import ContactUs from 'widgets/ContactUs';
import Wrapper from 'shared/Wrapper';

import { schema1C } from 'constants/ContactForm/validateScheme';
import { placeholders1C } from 'constants/ContactForm/constants';
import { projectsData } from 'constants/1C/projects/data';

export default function Page1C() {
  return (
    <Wrapper>
      <Greetings />
      <Services />
      <Configurations />
      <DevelopmentCycle />
      <Projects {...projectsData} />
      <ContactUs validationSchema={schema1C} placeholders={placeholders1C} />
    </Wrapper>
  );
}
