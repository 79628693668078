import React, { useState } from 'react';
import { HStack, Box, Stack } from '@chakra-ui/react';

import SliderControls from 'features/SliderControls';
import ExpertiseCard from '~entities/ExpertiseCard';
import BlockHeader from 'shared/BlockHeader';
import Slider from 'shared/Slider';
import { useSwiperControls } from 'shared/hooks/useSwiperControls';

import { servicesData, servicesCardsStyles } from 'constants/1C/services';

export default function Services1C() {
  const [swiper, setSwiper] = useState();
  const { slideNext, slidePrev } = useSwiperControls(swiper);

  return (
    <Stack w="full" align="start" spacing={{ laptop: '48px', tablet: '32px', mobile: '24px' }}>
      <HStack w="full" justify="space-between" align="end">
        <BlockHeader>Сервисы и компетенции</BlockHeader>
        <SliderControls slideNext={slideNext} slidePrev={slidePrev} />
      </HStack>
      <Box w="100%" overflow="hidden">
        <Slider
          controller={swiper}
          setController={setSwiper}
          slidesData={servicesData}
          SlideComponent={ExpertiseCard}
          slideStyle={servicesCardsStyles}
          mousewheel={false}
          slidesPerView="auto"
        />
      </Box>
    </Stack>
  );
}
